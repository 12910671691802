<template>
    <GlobalToasts />
    <NavBar />
    <slot />
    <Footer />
    <GlobalColorNotificationModal />
</template>

<script setup lang="ts">
import NavBar from "@/Components/Frontend/Nav/NavBar.vue"
import Footer from "@/Components/Frontend/Global/Footer.vue"
import {computed, onMounted, ref, watch} from "vue"
import { getManufacturer} from "@/util/manufacturers"
import {useThemeStore} from "@/stores/ThemeStore"
import '@stripe/stripe-js'
import GlobalToasts from "@/Components/Frontend/Global/GlobalToasts.vue"
import GlobalColorNotificationModal from "@/Components/Frontend/Global/GlobalColorNotificationModal.vue";
import {useToastStore} from "@/stores/ToastStore";
import {usePage} from "@inertiajs/vue3";
import {useCartStore} from "@/stores/CartStore";

const theme = useThemeStore()

const currentSubdomain = ref('')
const toast = useToastStore()
const cart = useCartStore()
const needsWelcomeToast = computed(() => cart.needsWelcomeToast)

function setTheme(subdomain: string) {
    const dealer = getManufacturer(subdomain)
    theme.setManufacturer(dealer)
    Object.entries(dealer.theme).forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--${key}`, value)
    })
}

onMounted(() => {
    //Theme setting
    currentSubdomain.value = window.location.hostname.split('.')[0]
    setTheme(currentSubdomain.value)

    //subdomain refferal toasts
    const baseDomain = getBaseDomain()
    if(document.referrer.includes(baseDomain) && theme.currentManufacturer.name === 'Quadzilla' && needsWelcomeToast.value) {
        cart.needsWelcomeToast = false
        toast.addToast('success', 'Welcome back to Quadzilla!')
    }
    if(theme.currentManufacturer.name !== 'Quadzilla') {
        cart.needsWelcomeToast = true
    }
})

watch(currentSubdomain, (newSubdomain) => {
    setTheme(newSubdomain)
    theme.subdomain = newSubdomain
})

function getBaseDomain() {
    const baseDomain = usePage().props.system.base_url as string ?? 'https://quadzilla.test'
    const domainParts = baseDomain
        .replace('https://', '')
        .replace('http://', '')
        .split('.')

    let rootDomain: string
    if (domainParts[domainParts.length - 1].includes('uk'))
        rootDomain = '.' + domainParts[domainParts.length - 3] + '.'
    else
        rootDomain = '.' + domainParts[domainParts.length - 2] + '.'

    return rootDomain
}

</script>
